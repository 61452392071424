/**
 * 100: Ultra Light
 * 200: Thin
 * 300: Light
 * 400: Regular
 * 500: Semi Bold
 * 600: Bold
 * 700: Extra Bold
 * 800: Heavy
 * 900: Ultra Heavy
 */

$font-weight-light:             300;
$font-weight-regular:           400;
$font-weight-medium:            500;
$font-weight-semi-bold:         600;
$font-weight-bold:              700;
$font-weight-extra-bold:        900;

$font-headline: 'Poppins', sans-serif;
$font-sans: 'Source Sans Pro', sans-serif;
$font-serif: 'Roboto Slab', serif;

@mixin font-reg() {
	font-family: $font-sans;
	font-weight: $font-weight-regular;
}
%font-reg {
	@include font-reg;
}

@mixin font-medium() {
	font-family: $font-headline;
	font-weight: $font-weight-medium;
}
%font-medium {
	@include font-medium;
}

@mixin font-serif() {
	font-family: $font-serif;
	font-weight: $font-weight-light;
}
%font-serif {
	@include font-serif;
}

/**
 * FONT SIZE MIXINS
 **/
@mixin font-size-h1() {
	//font-size: min(max(32px, 4vw), 60px);

	@include font-size(4.2, 4.8);

	@include respond-to(tablet) {
		@include font-size(4, 4.8);
	}
	@include respond-to(display) {
		@include font-size(5, 6.4, -20);
	}
}
@mixin font-size-h2() {
	@include font-size(3.2, 3.8);
}
@mixin font-size-h3() {
	@include font-size(2.4, 2.8);
}
@mixin font-size-h4() {
	@include font-size(1.8, 2.2);
}
@mixin font-size-h5() {
	@include font-size(1.6, 2.2);
}
@mixin font-size-h6() {
	@include font-size(1.4, 1.8);
}

@mixin font-size-body() {
	@include font-size(1.5, 2.4, 0);
}

@mixin font-size-body-mini() {
	@include font-size(1.1);
}

/**
 * FONT STYLE MIXINS
 */



@mixin font-style-body() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body;
}

@mixin font-style-body-mini() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body-mini;
}

@mixin font-style-headline-1() {
	@extend %font-medium;
	//include font-semi-bold;
	@include font-size-h1;
}
@mixin font-style-headline-2() {
	@extend %font-medium;
	//include font-bold;
	@include font-size-h2;
}
@mixin font-style-headline-3() {
	@extend %font-medium;
	//include font-bold;
	@include font-size-h3;
}
@mixin font-style-headline-4() {
	@extend %font-medium;
	//include font-bold;
	@include font-size-h4;
}
@mixin font-style-headline-5() {
	@extend %font-medium;
	//include font-bold;
	@include font-size-h5;
}
@mixin font-style-headline-6() {
	@extend %font-medium;
	//include font-bold;
	@include font-size-h6;
}
@mixin font-style-quote() {
	@extend %font-serif;
	@include font-size-h4;
}