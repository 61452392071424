* {
	box-sizing : border-box;
}
@import "../vendor/breakpoint/breakpoint";
@import "../vendor/susy/susy";

@import "helpers/mixins.scss";
@import "variables.scss";
@import "fonts-mixins.scss";

.force-repaint {
	animation: repaint 2000ms;
	animation-iteration-count: infinite;
}

@keyframes repaint {
	from {
		width: 99.999%
	}
	to {
		width: 100%
	}
}

BODY {
}

@import "blocks/_blocks.scss";
@import "views/_views.scss";


//last rule show content / asyc loaded css

BODY {
	&.layout--livemode {
		visibility: visible;
		opacity: 1;
	}
}



